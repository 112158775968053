 /* eslint-disable react/no-danger */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { graphql } from 'gatsby';
import HomeLayout from '../components/layouts/home-layout';
import SEO from '../components/seo';
import AvailableListings from '../components/available-listings';
import SearchIcon from '../../static/assets/icons/icon-search.svg';

export const query = graphql`
  query GetIndexWithLocale($locale: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    contentfulHomepageSettings(node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      keywords {
        keywords
      }
      firstBlockHeading
      firstBlockContent {
        childMarkdownRemark {
          html
        }
      }
      secondBlockHeading
      secondBlockContent {
        childMarkdownRemark {
          html
        }
      }
      secondBlockImage {
        fluid {
          src
        }
        description
      }
      thirdBlockHeading
      thirdBlockContent {
        childMarkdownRemark {
          html
        }
      }
      thirdBlockImage {
        file {
          url
        }
        description
      }
    }

    jbapi {
      jobs(filter: { status: "published" }, limit: 10, sort: { updatedAt: DESC }) {
        id
        title
        status
        hiringStatus
        permalink
        budget
        benefits
        type
        department
        createdAt
        enabled
        updatedAt
        description
        location
        company {
          name
          username
          profilePicture
          headquarters
          website
        }
      }
    }
  }
`;

function IndexPage({ data }) {
  const { contentfulHomepageSettings: settings, jbapi } = data;
  return (
    <HomeLayout>
      <SEO
        title={settings.title}
        keywords={settings.keywords.keywords.split(',')}
        description={settings.description.description}
      />
      <div className="text-center">
        <div className="text-left flex flex-col-reverse mt-10 wrapper">
          <div className="text-xl lg:text-2xl p-6 md:p-0 md:pl-10 w-full">
            <h1 className="md:text-6xl font-extrabold">{settings.thirdBlockHeading}</h1>
            <ul
              className="list-custom"
              dangerouslySetInnerHTML={{
                __html: settings.thirdBlockContent.childMarkdownRemark.html,
              }}
            />
          </div>
          <div className="text-xl md:text-2xl p-4 w-3/4 mx-auto">
            <img
              src={settings.thirdBlockImage.file.url}
              className="block m-auto"
              alt={settings.thirdBlockImage.description}
            />
          </div>
        </div>

        <div className="text-center mb-16">
          <a
            href={`${process.env.GATSBY_APP_ROOT}/signup/employer`}
            className="cta shadow-lg"
          >
            Try it FREE for 30 Days
          </a>
        </div>
        <div className="gradient-cream">
          <div className="text-left flex flex-col md:flex-row wrapper">
            <div className="text-xl md:text-2xl p-4 w-full">
              <h1 className="md:text-6xl font-extrabold">{settings.firstBlockHeading}</h1>
              <h2 className="md:text-3xl mb-4">WeRemote is free for candidates.</h2>
              <div
                className="leading-normal w-full md:w-3/4"
                dangerouslySetInnerHTML={{
                  __html: settings.firstBlockContent.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>

          <div className="wrapper text-left flex flex-col lg:flex-row">
            <div className="text-xl lg:text-2xl">
              <ul
                className="list-custom"
                dangerouslySetInnerHTML={{
                  __html: settings.secondBlockContent.childMarkdownRemark.html,
                }}
              />
            </div>
            <div className="text-xl md:text-2xl p-4 w-full lg:w-1/2">
              <img
                src={settings.secondBlockImage.fluid.src}
                className="block m-auto"
                alt={settings.secondBlockImage.description}
              />
            </div>
          </div>
          <div className="text-center">
            <a
              href={`${process.env.GATSBY_APP_ROOT}/signup`}
              className="cta shadow-lg mb-8"
            >
              Sign Up as A Candidate
            </a>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
}

export default IndexPage;
