import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const CTA_CONTENT_QUERY = graphql`
  query CTAContentQuery {
    settings: contentfulHomepageSettings {
      ctaQuestion
      ctaLinkText
      ctaLinkHref
      ctaLinkPrice
      ctaLinkFree
    }
  }
`;

const Hero = () => {
  const { settings } = useStaticQuery(CTA_CONTENT_QUERY);
  return (
    <div className="md:justify-between md:flex-row wrapper text-left">
      <h1 className="text-left text-5xl md:text-6xl lg:text-7xl font-extrabold leading-tight mb-12">
        You dont need an enterprise budget
        to <span className="gradient text-bg">find remote talent.</span>
      </h1>
      <p className="text-xl md:text-2xl font-medium leading-10 text-gray-700">
        Start hiring now, using our remote hiring platform. WeRemote.eu is not your average remote jobs board. We're actively building tooling to make finding and hiring remote talent easier than ever.
      </p>
      <a href={settings.ctaLinkHref} className="cta shadow-lg text-center">
        Start Hiring
      </a>
      <a className="mx-2 text-lg font-semibold no-underline border-b-2 text-theme-blue border-theme-blue border-dashed" href={`${process.env.GATSBY_APP_ROOT}/signup`}>I&apos;m looking for a job</a>
    </div>
  );
};

export default Hero;
